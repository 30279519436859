import axios from 'axios';

export default class PagosService {
    getPagosMes(year){
        const url = `/api/pagos/mes/${year}`;
		return axios.get(url).then(res=>res.data.data);
    }

    getSaldoPaciente(){
        const url = `/api/pagos/saldo`;
		return axios.get(url).then(res=>res.data.data);
    }
}